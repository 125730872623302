import React from "react";
import PropTypes from "prop-types";
import {Col, Container, Row} from "reactstrap";
import TitleBlock from "../blocks/TitleBlock";
import Photo from "../blocks/Photo";
import Lightbox from "../blocks/Lightbox";

const Photos = (props) => {
    return (
        <div className="about-us pb-0" id="photos">
            <div className="about-office pb-5" style={{paddingTop: 0}}>
                <Container>
                    {props.title &&
                    <Row className="text-center">
                        <Col className="ml-auto mr-auto pb-5" md="8">
                            <TitleBlock
                                title={props.title}
                                description={props.description}
                                headingLevel="h2"
                                extendedClasses="mb-4 color-sea font-weight-bold"
                            />
                        </Col>
                    </Row>
                    }
                    <Row className="d-flex">
                        <Lightbox images={props.photos.edges} />
                    </Row>

                </Container>
            </div>
        </div>
    );
};

Photos.propTypes = {
    photos: PropTypes.object.isRequired,
}

export default Photos;
